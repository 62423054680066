import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { LocksActionsNames } from "./locks.actions";
import { LocksState, LocksAction } from "./type";

const initialState: LocksState = {
  isLoading: false,
  error: null,
  locks: [],
};

const usersReducer = (
  state: LocksState = initialState,
  action: LocksAction
): LocksState => {
  switch (action.type) {
    case LocksActionsNames.RELEASE_MIGRATION_LOCK.START:
      return {
        ...state,
        isLoading: true,
      }
    case LocksActionsNames.RELEASE_MIGRATION_LOCK.FULFILLED:
      return {
        ...state,
        isLoading: false,
      }
    case LocksActionsNames.RELEASE_MIGRATION_LOCK.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case LocksActionsNames.ADD_LOCK.START:
      return {
        ...state,
        isLoading: true,
      }
    case LocksActionsNames.ADD_LOCK.FULFILLED:
      return {
        ...state,
        isLoading: false,
      }
    case LocksActionsNames.ADD_LOCK.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case LocksActionsNames.GET_LOCKS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        locks: []
      };
    case LocksActionsNames.GET_LOCKS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        locks: action.payload
      };
    case LocksActionsNames.GET_LOCKS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case LocksActionsNames.CHANGE_LOCK_STATUS.START:
      return {
        ...state,
        isLoading: true,
      };
    case LocksActionsNames.CHANGE_LOCK_STATUS.FULFILLED:
      const updatedLocks = state.locks.map(l => l.caseId === action.payload?.caseId ?
        { ...l, 
          lockStatus: action.payload?.lockStatus,
          statusUpdateDate: action.payload?.statusUpdateDate,
        }
        : l);
      return {
        ...state,
        isLoading: false,
        locks: updatedLocks,
      };
    case LocksActionsNames.CHANGE_LOCK_STATUS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default usersReducer;
