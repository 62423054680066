import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { WaitingListsActions } from "../../../store/entities/waitingLists/waitingLists.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { ISortItem } from "../../../models/table";
import { getHebrewDate, sortDataByDate } from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ISite } from "../../../models/site";
import { ICompound, IDynamicList } from "../../../store/entities/general/type";
import Filters from "../../../components/Container/Filters/Filters";
import { CONSTANTS } from "../../../utils/constants";
import Title from "../../../components/UI/Title/Title";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { IPage } from "../../../models/page";
import { IWaitingList } from "../../../store/entities/waitingLists/type";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { WaitingListSearchValidation } from "../../../utils/formValidation/waitingLists";
import { PERMISSIONS } from "../../../utils/permissions";
import { TableActions } from "../../../store/entities/table/table.actions";
import Dialog from "../../../components/UI/Dialog/Dialog";
import Alert from "../../../components/UI/Alert/Alert";
import AutoComplete from "../../../components/Container/InputFields/AutoComplete/AutoComplete";
import { ICity } from "../../../models/city";

import "./ManageWaitingLists.scss";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

const ManageWaitingLists = ({ permission, nextRoute }: IPage) => {
  const [showSuccess, setShowSuccess] = useState<boolean>();
  const [rows, setRows] = useState<any[]>([]);
  const [showSiteOpenModal, setShowSiteOpenModal] = useState<boolean>(false);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
  const [statusList, setStatusList] = useState<IAutoCompleteItem[]>([]);
  const [typeList, setTypeList] = useState<IAutoCompleteItem[]>([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [openSiteForOrdersValues, setOpenSiteForOrdersValues] = useState<{
    cityId: number | null;
    siteId: number | null;
  } | null>(null);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, updateParams, params } = useUrlSearchParam();
  const navigate = useNavigate();

  const { queryParams, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
  const { siteListDetails, sites } = useAppSelector((state) => state.sites);
  const { formikProps } = useAppSelector((store) => store.form);

  const {
    appLanguage,
    siteCompounds,
    compoundNames,
    waitingListTypes,
    waitingListStatuses,
    compoundsDetails,
    cities
  } = useAppSelector((state) => state.general);
  const { waitingLists } = useAppSelector((state) => state.waitingLists);

  let siteIsOpenModalTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (waitingLists?.length) {
      let compoundsIds: number[] = [];
      waitingLists.map((item: IWaitingList) => {
        item?.compoundId && compoundsIds.push(item?.compoundId);
      });

      if (compoundsIds?.length) {
        dispatch(
          GeneralActions.getCompoundsByIds(Array.from(new Set(compoundsIds)))
        );
      }
    }
  }, [waitingLists]);

  useEffect(() => {
    dispatch(WaitingListsActions.getWaitingLists(params));
    if (currentPage !== 1) {
      dispatch(TableActions.setTableData({ currentPage: 1 }));
    }
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(GeneralActions.getCities());
  }, [dispatch]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          WaitingListsActions.getWaitingLists(
            undefined,
            sortModel,
            false,
            nextPageNumber
          )
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber, sortModel, dispatch]);

  useEffect(() => {
    dispatch(SitesActions.getSiteListDetails());
    dispatch(GeneralActions.getWaitingListTypes());
    dispatch(GeneralActions.getWaitingListStatuses());
  }, [dispatch]);

  // Remove compound value if there is no site selected
  useEffect(() => {
    if (
      !formikProps?.values?.siteId &&
      formikProps?.values?.compoundId &&
      formikProps?.setFieldValue
    ) {
      formikProps?.setFieldValue("compoundId", null);
    }
  }, [formikProps]);

  useEffect(() => {
    if (waitingListStatuses?.length) {
      const newStatuses: IAutoCompleteItem[] = waitingListStatuses.map(
        (status: IDynamicList) => ({
          id: status.id,
          label: appLanguage === "hebrew" ? status.nameHE : status.nameEN ?? ""
        })
      );

      setStatusList(newStatuses);
    }
  }, [appLanguage, waitingListStatuses]);

  useEffect(() => {
    if (waitingListTypes?.length) {
      const newStatuses: IAutoCompleteItem[] = waitingListTypes.map(
        (type: IDynamicList) => ({
          id: type.id,
          label: appLanguage === "hebrew" ? type.nameHE : type.nameEN ?? ""
        })
      );

      setTypeList(newStatuses);
    }
  }, [appLanguage, waitingListTypes]);

  useEffect(() => {
    if (cities?.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id as number,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN ?? ""
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (sites?.length) {
      let newSites: IAutoCompleteItem[] = sites.filter(
        (site: ISite) => site.isActive
      );

      newSites = sites.map((site: ISite) => ({
        id: site.id as number,
        label: appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
      }));

      setSiteList(newSites);
    }
  }, [appLanguage, sites]);

  useEffect(() => {
    return () => clearTimeout(siteIsOpenModalTimeout);
  }, [siteIsOpenModalTimeout]);

  const columns: any[] = [
    {
      id: 'Id',
      numeric: true,
      disablePadding: true,
    },
    {
      id: 'siteId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.site"),
    },
    {
      id: 'compoundId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.compound"),
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.firstName"),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.lastName"),
    },
    // {
    //   id: 'identificationNumber',
    //   numeric: true,
    //   disablePadding: true,
    //   label: rcTranslate("waitingLists.manageWaitingLists.filters.identity"),
    // },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.email"),
    },
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.phoneNumber"),
    },
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.created"),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.status")
    },
    {
      id: 'statusUpdateDate',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("waitingLists.manageWaitingLists.filters.statusUpdateDate"),
    },
  ];

  const navigateToOderManagement = useCallback(
    (orderId: string) => {
      updateParams({ orderId });

      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          "/?orderId=" +
          orderId
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (!waitingLists?.length) return setRows([]);

    const wiatingListsRows: any[] = waitingLists.map(
      (item: IWaitingList, index: number) => {
        const itemSite = () => {
          let site = "";

          if (siteListDetails.length && item) {
            const foundSite = siteListDetails?.find(
              (site: ISite) => item.siteId === site.id
            );

            if (foundSite) {
              const siteName =
                appLanguage === "hebrew"
                  ? foundSite.nameHE
                  : foundSite.nameEN ?? "";

              return `${foundSite.id} - ${siteName}`;
            }
          }

          return site;
        };

        const itemCompound = () => {
          if (!item.compoundId) return "";

          if (compoundsDetails?.length && item) {
            const foundCompound = compoundsDetails?.find(
              (compound: IDynamicList) => item.compoundId === compound.id
            );

            if (foundCompound) {
              return appLanguage === "hebrew"
                ? foundCompound.nameHE
                : foundCompound.nameEN ?? "";
            }
          }

          return "";
        };

        const itemStatus = () => {
          if (!item.status) return "";

          if (waitingListStatuses?.length && item) {
            const foundStatus = waitingListStatuses?.find(
              (status: IDynamicList) => item?.status === status.id
            );

            if (foundStatus) {
              return appLanguage === "hebrew"
                ? foundStatus.nameHE
                : foundStatus.nameEN ?? "";
            }
          }

          return "";
        };

        let waitingListObject = {
          id: index,
          siteId: itemSite(),
          compoundId: itemCompound(),
          firstName: item?.firstName ?? "",
          lastName: item?.lastName ?? "",
          // identificationNumber: item?.identificationNumber ?? "",
          email: item?.email ?? "",
          phoneNumber: item?.phoneNumber ?? "",
          created: item?.created && getHebrewDate(item.created),
          status: itemStatus(),
          statusUpdateDate:
            item?.statusUpdateDate && getHebrewDate(item.statusUpdateDate),
          actions: (
            <ActionButtons
              style={{ marginRight: "auto" }}
              hide={
                [PERMISSIONS.GET_ORDERS].filter((v: string) =>
                  appPermissions.includes(v)
                ).length === 0 || !item?.orderId
              }
              menuItems={[
                {
                  label: "lockers.manageLockers.oderManagement",
                  onClick: () =>
                    navigateToOderManagement(item?.orderId?.toString()),
                  show: item?.orderId && PERMISSIONS.GET_ORDERS ? true : false
                }
              ]}
            />
          )
        };

        return waitingListObject;
      }
    );

    setRows(wiatingListsRows);
  }, [
    appLanguage,
    siteListDetails,
    waitingListStatuses,
    waitingLists,
    compoundsDetails,
    navigateToOderManagement,
    rcTranslate
  ]);

  useEffect(() => {
    if (siteListDetails.length) {
      siteListDetails.sort((a, b) => (a.id > b.id ? 1 : -1));
      const newSites: IAutoCompleteItem[] = siteListDetails.map(
        (site: ISite) => ({
          id: site.id,
          label: `${site.id} - ${
            appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
          }`
        })
      );

      setSiteList(newSites);
    }
  }, [appLanguage, siteListDetails]);

  // Get compounds by site after choosing a site in the search
  useEffect(() => {
    const siteId = formikProps?.values?.siteId;

    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, formikProps?.values?.siteId]);

  // Get the compounds select items of a specific site in the search
  useEffect(() => {
    if (siteCompounds?.length && compoundNames?.length) {
      const foundCompounds: ICompound[] = compoundNames.filter(
        (item) =>
          siteCompounds.find(
            (siteCompound) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: appLanguage === "hebrew" ? item.nameHE : item.nameEN ?? ""
          };
        }
      );

      setSiteCompoundList(newSiteCompounds);
    }
  }, [appLanguage, siteCompounds, compoundNames]);

  // Remove siteId if no cityId, filter sites by selected city
  useEffect(() => {
    if (openSiteForOrdersValues?.cityId) {
      dispatch(
        SitesActions.getSites({ cityId: openSiteForOrdersValues?.cityId })
      );
    } else {
      setOpenSiteForOrdersValues((prevState: any) => ({
        ...prevState,
        siteId: null
      }));
    }
  }, [openSiteForOrdersValues?.cityId, dispatch]);

  const searchFields: IFormFields = {
    initialValues: {
      waitingListType: getValueFromQuery("waitingListType", "number"),
      siteId: getValueFromQuery("siteId", "number"),
      compoundId: getValueFromQuery("compoundId", "number"),
      waitingListId: getValueFromQuery("waitingListId", "number"),
      firstName: getValueFromQuery("firstName"),
      lastName: getValueFromQuery("lastName"),
      identificationNumber: getValueFromQuery("identificationNumber"),
      phoneNumber: getValueFromQuery("phoneNumber"),
      email: getValueFromQuery("email"),
      status: getValueFromQuery("status", "number"),
      dateRangeBy: getValueFromQuery("dateRangeBy"),
      fromDate: getValueFromQuery("fromDate"),
      toDate: getValueFromQuery("toDate")
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "waitingListType",
            label: "waitingLists.manageWaitingLists.filters.waitingListType",
            items: typeList,
            props: { multiple: false }
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "siteId",
            label: "waitingLists.manageWaitingLists.filters.site",
            items: siteList,
            props: { multiple: false }
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "compoundId",
            label: "waitingLists.manageWaitingLists.filters.compound",
            items: siteCompoundList,
            props: { multiple: false }
          }
        ]
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "waitingListId",
                    label: "waitingLists.manageWaitingLists.filters.waitingListId"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "firstName",
                    label: "waitingLists.manageWaitingLists.filters.firstName"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "lastName",
                    label: "waitingLists.manageWaitingLists.filters.lastName"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "identificationNumber",
                    label: "waitingLists.manageWaitingLists.filters.identity"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "phoneNumber",
                    label: "waitingLists.manageWaitingLists.filters.phoneNumber"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "email",
                    label: "waitingLists.manageWaitingLists.filters.email"
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "status",
                    label: "waitingLists.manageWaitingLists.filters.status",
                    items: statusList,
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "dateRangeBy",
                    label:
                      "waitingLists.manageWaitingLists.filters.dateRangeBy.label",
                    items: [
                      {
                        key: "0",
                        value:
                          "waitingLists.manageWaitingLists.filters.dateRangeBy.statusUpdateDate"
                      },
                      {
                        key: "1",
                        value:
                          "waitingLists.manageWaitingLists.filters.dateRangeBy.createdDate"
                      }
                    ],
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromDate",
                    label: "waitingLists.manageWaitingLists.filters.fromDate",
                    props: { getStartOfDay: true }
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toDate",
                    label: "waitingLists.manageWaitingLists.filters.toDate",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromPairingDate)
                    }
                  }
                ]
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch"
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch"
                      },
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: ""
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  const renderOpenSiteModalContent = () => {
    const handleOpenSiteForOrdersValueChange = (
      label: string,
      value: number
    ) => {
      setOpenSiteForOrdersValues((prevState: any) => ({
        ...prevState,
        [label]: value
      }));
    };

    return (
      <>
        <AutoComplete
          items={cityList}
          onChange={(val) =>
            handleOpenSiteForOrdersValueChange("cityId", val as number)
          }
          label="waitingLists.manageWaitingLists.siteOpenModal.city"
          id="compoundClassesAutoComplete"
          value={(openSiteForOrdersValues?.cityId as number) ?? ""}
          multiple={false}
        />

        <AutoComplete
          items={siteList}
          onChange={(val) =>
            handleOpenSiteForOrdersValueChange("siteId", val as number)
          }
          label="waitingLists.manageWaitingLists.siteOpenModal.site"
          id="compoundClassesAutoComplete"
          value={(openSiteForOrdersValues?.siteId as number) ?? ""}
          multiple={false}
          disabled={!openSiteForOrdersValues?.cityId}
        />
      </>
    );
  };

  const closeDialog = () => {
    setShowSiteOpenModal(false);
  };

  const handleSiteIsOpen = async () => {
    try {
      if (!openSiteForOrdersValues?.siteId) return;

      let res: any;

      res = await dispatch(
        WaitingListsActions.siteIsOpenForOrders(openSiteForOrdersValues.siteId)
      );

      if (!res?.isSucceeded) return;

      setShowSuccess(true);

      siteIsOpenModalTimeout = setTimeout(() => setShowSuccess(false), 3000);
    } catch (err) {
      console.log(err);
    } finally {
      setOpenSiteForOrdersValues(null);
      closeDialog();
    }
  };

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(WaitingListsActions.getWaitingLists(queryParams, model));
  };

  const generalButtons = [
    {
      title: "waitingLists.manageWaitingLists.siteOpeningMessage",
      onClick: () => setShowSiteOpenModal(true),
      show: appPermissions.includes(
        PERMISSIONS.SEND_SITE_OPEN_FOR_ORDERS_NOTIFICATION
      )
    }
  ];

  const tableButtons = [
    {
      title: "waitingLists.manageWaitingLists.messageToCompound"
      // onClick: () => printClosetCellsQR(),
      // show: true,
    }
  ];

  const shouldExtendFilter: () => boolean = () => {
    let extendedFields: any = searchFields?.formData?.find(
      (formItem) => formItem?.id === "extendedFieldsWrapper"
    );
    if (extendedFields && queryParams) {
      extendedFields = extendedFields.fields[0]?.fields[0]?.fields?.map(
        (field: any) => field.id
      );

      if (extendedFields.length) {
        const foundParams: string[] = extendedFields?.filter((i: string) =>
          Object.keys(queryParams).includes(i)
        );

        if (foundParams?.length) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div className="manageLockers__wrapper">
      <Title text={rcTranslate("waitingLists.manageWaitingLists.pageTitle")} />

      <Filters
        fields={searchFields}
        filtersValidation={WaitingListSearchValidation}
        isExtended={shouldExtendFilter()}
      />

      <TableButtons
        generalButtons={generalButtons}
        tableButtons={tableButtons}
      />
    <div className="manageWaitingListTable">
      <EnhancedTable      
        rows={rows}
        columns={columns}
        sortDataByDate={sortDataByDate}
        checkboxSelection={true}
        expandable={false}
        showId={false}
        />
    </div>

      {/* Show modal for a new open site notification */}
      {showSiteOpenModal && (
        <Dialog
          className="manageWaitingLists__siteOpeningDialog"
          title={rcTranslate(
            "waitingLists.manageWaitingLists.siteOpeningModalTitle"
          )}
          body={renderOpenSiteModalContent()}
          onClose={closeDialog}
          onConfirm={handleSiteIsOpen}
          confirmTitle={"button.confirm"}
          // Disable confirm button if one of the fields is null
          disabled={
            openSiteForOrdersValues
              ? Object.values(openSiteForOrdersValues).filter((i) => i === null)
                  .length > 0
              : true
          }
        />
      )}

      {showSuccess && (
        <Alert
          message={rcTranslate(
            "waitingLists.manageWaitingLists.siteIsOpenSuccess"
          )}
          color="success"
        />
      )}
    </div>
  );
};
export default ManageWaitingLists;
