import { ISite } from "../../../models/site";
import { getNewErrorsArray } from "../../../utils/formatters";
import { SiteHierarchy } from "../orders/type";
import { GeneralActionsNames } from "./general.actions";
import { GeneralAction, GeneralState } from "./type";

const initialState: GeneralState = {
  isLoading: false,
  error: null,
  cities: [],
  rentalRoutes: [],
  districts: [],
  compoundNames: [],
  siteCompounds: [],
  colors: [],
  manufacturers: [],
  doorCladdings: [],
  orderRegistrationPlatforms: [],
  orderPaymentMethods: [],
  operationTypes: [],
  userTypes: [],
  lockerStatuses: [],
  doorStates: [],
  compoundsDetails: [],
  lockStatuses: [],
  pemTypes: [],
  orderStatuses: [],
  waitingListStatuses: [],
  waitingListTypes: [],
  appLanguage: "hebrew",
  paymentStatuses: [],
  subscriptionModels: [],
  siteHierarchy: {} as SiteHierarchy,
  sitesPerOrder: [] as ISite[],
  unaviableCellsForSite: []
};

const generalReducer = (
  state: GeneralState = initialState,
  action: GeneralAction
): GeneralState => {
  switch (action.type) {
    case GeneralActionsNames.GET_CITIES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_CITIES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        cities: action.payload,
      };
    case GeneralActionsNames.GET_CITIES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.ADD_CITY.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.ADD_CITY.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.ADD_CITY.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.UPDATE_CITY.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.UPDATE_CITY.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.UPDATE_CITY.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_RENTAL_ROUTES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_RENTAL_ROUTES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        rentalRoutes: action.payload,
      };
    case GeneralActionsNames.GET_RENTAL_ROUTES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_DISTRICTS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_DISTRICTS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        districts: action.payload,
      };
    case GeneralActionsNames.GET_DISTRICTS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUND_NAMES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_COMPOUND_NAMES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        compoundNames: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUND_NAMES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_SITE.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        siteCompounds: [],
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_SITE.FULFILLED:
      return {
        ...state,
        isLoading: false,
        siteCompounds: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_SITE.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.ADD_COMPOUND.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.ADD_COMPOUND.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.ADD_COMPOUND.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.UPDATE_COMPOUND.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.UPDATE_COMPOUND.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.UPDATE_COMPOUND.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.REMOVE_COMPOUND.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.REMOVE_COMPOUND.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.REMOVE_COMPOUND.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.SET_GENERAL_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.SET_GENERAL_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case GeneralActionsNames.SET_GENERAL_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        orderRegistrationPlatforms: action.payload,
      };
    case GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case GeneralActionsNames.PAYMENT_METHODS.START:
        return {
          ...state,
          error: null,
          isLoading: true,
        };
      case GeneralActionsNames.PAYMENT_METHODS.FULFILLED:
        return {
          ...state,
          isLoading: false,
          orderPaymentMethods: action.payload,
        };
      case GeneralActionsNames.PAYMENT_METHODS.REJECTED:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
    case GeneralActionsNames.ORDER_OPERATION_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.ORDER_OPERATION_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        operationTypes: action.payload,
      };
    case GeneralActionsNames.ORDER_OPERATION_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_COLORS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_COLORS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        colors: action.payload,
      };
    case GeneralActionsNames.GET_COLORS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GeneralActionsNames.GET_DOOR_CLADDINGS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case GeneralActionsNames.GET_DOOR_CLADDINGS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        doorCladdings: action.payload,
      };
    case GeneralActionsNames.GET_DOOR_CLADDINGS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_MANUFACTURERS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_MANUFACTURERS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        manufacturers: action.payload,
      };
    case GeneralActionsNames.GET_MANUFACTURERS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_IDS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_IDS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        compoundsDetails: action.payload,
      };
    case GeneralActionsNames.GET_COMPOUNDS_BY_IDS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.USER_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.USER_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        userTypes: action.payload,
      };
    case GeneralActionsNames.USER_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CELL_STATUSES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.CELL_STATUSES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        lockerStatuses: action.payload,
      };
    case GeneralActionsNames.CELL_STATUSES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.LOCK_STATUSES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.LOCK_STATUSES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        lockStatuses: action.payload,
      };
    case GeneralActionsNames.LOCK_STATUSES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.DOOR_STATES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.DOOR_STATES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        doorStates: action.payload,
      };
    case GeneralActionsNames.DOOR_STATES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.PEM_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.PEM_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        pemTypes: action.payload,
      };
    case GeneralActionsNames.PEM_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.ORDER_STATUSES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.ORDER_STATUSES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        orderStatuses: action.payload,
      };
    case GeneralActionsNames.ORDER_STATUSES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.WAITING_LIST_STATUSES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.WAITING_LIST_STATUSES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        waitingListStatuses: action.payload,
      };
    case GeneralActionsNames.WAITING_LIST_STATUSES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.WAITING_LIST_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.WAITING_LIST_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        waitingListTypes: action.payload,
      };
    case GeneralActionsNames.WAITING_LIST_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload),
      };
    case GeneralActionsNames.PAYMENT_STATUSES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.PAYMENT_STATUSES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        paymentStatuses: action.payload,
      };
    case GeneralActionsNames.PAYMENT_STATUSES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.SUBSCRIPTION_MODELS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GeneralActionsNames.SUBSCRIPTION_MODELS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        subscriptionModels: action.payload,
      };
    case GeneralActionsNames.SUBSCRIPTION_MODELS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.FULFILLED:
      return {
        ...state,
        isLoading: false,
        sitesPerOrder: action.payload
      };
    case GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.FULFILLED:
      return {
        ...state,
        isLoading: false,
        siteHierarchy: action.payload
      };
    case GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GeneralActionsNames.POST_ALLOCATE_CELL.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case GeneralActionsNames.POST_ALLOCATE_CELL.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case GeneralActionsNames.POST_ALLOCATE_CELL.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
      case GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.START:
        return {
          ...state,
          error: null,
          isLoading: true
        };
      case GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.FULFILLED:
        return {
          ...state,
          unaviableCellsForSite: action.payload,
          isLoading: false,
        };
      case GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.REJECTED:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        }
  }
  return state;
};

export default generalReducer;
