export const PERMISSIONS = {
  ADD_USER: "ADD_USER",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USERS: "GET_USERS",
  UPDATE_CUSTOMER_DETAILS: "UPDATE_CUSTOMER_DETAILS",
  GET_CUSTOMER_DETAILS: "GET_CUSTOMER_DETAILS",
  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_PERMISSIONS: "GET_PERMISSIONS",
  GET_PERMISSION_GROUPS: "GET_PERMISSION_GROUPS",
  GET_USER_PERMISSION_KEYS: "GET_USER_PERMISSION_KEYS",
  UPDATE_USER_PERMISSIONS: "UPDATE_USER_PERMISSIONS",
  GET_ROLES: "GET_ROLES",
  ADD_ROLE: "ADD_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  REMOVE_ROLE: "REMOVE_ROLE",
  GET_ROLE_DETAILS: "GET_ROLE_DETAILS",
  GET_DYNAMIC_LISTS: "GET_DYNAMIC_LISTS",
  GET_DYNAMIC_LIST_OPTIONS: "GET_DYNAMIC_LIST_OPTIONS",
  GET_DYNAMIC_LIST_OPTIONS_BY_KEY: "GET_DYNAMIC_LIST_OPTIONS_BY_KEY",
  GET_DYNAMIC_LIST_OPTIONS_BY_KEYS: "GET_DYNAMIC_LIST_OPTIONS_BY_KEYS",
  UPDATE_DYNAMIC_LIST_OPTION: "UPDATE_DYNAMIC_LIST_OPTION",
  ADD_CITY: "ADD_CITY",
  UPDATE_CITY: "UPDATE_CITY",
  GET_CITY_DETAILS: "GET_CITY_DETAILS",
  GET_CITIES: "GET_CITIES",
  ADD_SITE: "ADD_SITE",
  UPDATE_SITE: "UPDATE_SITE",
  GET_SITE_DETAILS: "GET_SITE_DETAILS",
  GET_SITES: "GET_SITES",
  GET_SITE_LIST: "GET_SITE_LIST",
  GET_SITE_TYPES: "GET_SITE_TYPES",
  GET_SUB_SITE_TYPES_BY_SITE_TYPE: "GET_SUB_SITE_TYPES_BY_SITE_TYPE",
  GET_SUB_SITE_TYPE_LIST: "GET_SUB_SITE_TYPE_LIST",
  ADD_COMPOUND: "ADD_COMPOUND",
  UPDATE_COMPOUND: "UPDATE_COMPOUND",
  REMOVE_COMPOUND: "REMOVE_COMPOUND",
  GET_COMPOUNDS_BY_SITE: "GET_COMPOUNDS_BY_SITE",
  GET_GRADES: "GET_GRADES",
  GET_GRADES_BY_COMPOUND: "GET_GRADES_BY_COMPOUND",
  UPDATE_COMPOUND_GRADES: "UPDATE_COMPOUND_GRADES",
  ADD_CELL_FORMATION: "ADD_CELL_FORMATION",
  UPDATE_CELL_FORMATION: "UPDATE_CELL_FORMATION",
  GET_CELL_FORMATIONS: "GET_CELL_FORMATIONS",
  ADD_COLUMN_FORMATION: "ADD_COLUMN_FORMATION",
  UPDATE_COLUMN_FORMATION: "UPDATE_COLUMN_FORMATION",
  GET_COLUMN_FORMATION_DETAILS: "GET_COLUMN_FORMATION_DETAILS",
  UPDATE_COLUMN_FORMATION_CELL_FORMATIONS:
    "UPDATE_COLUMN_FORMATION_CELL_FORMATIONS",
  GET_COLUMN_FORMATIONS: "GET_COLUMN_FORMATIONS",
  GET_ORDERS: "GET_ORDERS",
  GET_ORDER_EXTENDED_DETAILS: "GET_ORDER_EXTENDED_DETAILS",
  GET_CELLS: "GET_CELLS",
  GET_CLOSETS: "GET_CLOSETS",
  UPDATE_CLOSET: "UPDATE_CLOSET",
  ADD_CLOSET: "ADD_CLOSET",
  GET_CLOSET_DETAILS: "GET_CLOSET_DETAILS",
  GET_ORDER_HISTORY: "GET_ORDER_HISTORY",
  UPDATE_USERS_STATUS: "UPDATE_USERS_STATUS",
  GET_CITIES_SITES: "GET_CITIES_SITES",
  GET_SITE_HIERARCHY: "GET_SITE_HIERARCHY",
  RESEND_INITIAL_PASSWORD_LINK: "RESEND_INITIAL_PASSWORD_LINK",
  GET_CLOSET_FORMATIONS: "GET_CLOSET_FORMATIONS",
  GET_LOCKS: "GET_LOCKS",
  GET_CLOSET_FORMATION_DETAILS: "GET_CLOSET_FORMATION_DETAILS",
  ADD_CLOSET_FORMATION: "ADD_CLOSET_FORMATION",
  UPDATE_CLOSET_FORMATION_CELL_FORMATIONS:
    "UPDATE_CLOSET_FORMATION_CELL_FORMATIONS",
  UPDATE_CLOSET_FORMATION: "UPDATE_CLOSET_FORMATION",
  PAIR_LOCKS_WITH_CLOSET_CELLS: "PAIR_LOCKS_WITH_CLOSET_CELLS",
  PAIR_LOCK_WITH_CELL: "PAIR_LOCK_WITH_CELL",
  GENERATE_DATE_UPDATE_REQUEST_EC: "GENERATE_DATE_UPDATE_REQUEST_EC",
  GENERATE_DATE_UPDATE_RESPONSE_EC: "GENERATE_DATE_UPDATE_RESPONSE_EC",
  RELEASE_CELL: "RELEASE_CELL",
  ALLOCATE_CELL: "ALLOCATE_CELL",
  EXCHANGE_CELL: "EXCHANGE_CELL",
  ORDER_CANCELLATION: "ORDER_CANCELLATION",
  GET_WAITING_LISTS: "GET_WAITING_LISTS",
  UPDATE_SITES_STATUS: "UPDATE_SITES_STATUS",
  UPDATE_SITE_OPEN_FOR_ORDERS: "UPDATE_SITE_OPEN_FOR_ORDERS",
  CAN_MOBILY_CLOSET: "CAN_MOBILY_CLOSET",
  MOBILY_CLOSET: "MOBILY_CLOSET",
  SEND_SITE_OPEN_FOR_ORDERS_NOTIFICATION:
    "SEND_SITE_OPEN_FOR_ORDERS_NOTIFICATION",
  RELEASE_MIGRATION: "RELEASE_MIGRATION",
  GET_PRICING_PLANS:"GET_PRICING_PLANS",
  ADD_PRICING_PLAN:"ADD_PRICING_PLAN",
  UPDATE_PRICING_PLAN:"UPDATE_PRICING_PLAN",
  GET_PRICING_PLAN_DETAILS:"GET_PRICING_PLAN_DETAILS",
  REMOVE_PRICING_PLAN:"REMOVE_PRICING_PLAN",
  GET_SITE_PRICING_PLANS :"GET_SITE_PRICING_PLANS",
  UPDATE_SITE_PRICING_PLANS :"UPDATE_SITE_PRICING_PLANS",
  BLOCK_CELLS :"BLOCK_CELLS",
  RELEASE_BLOCK_CELLS :"RELEASE_BLOCK_CELLS",
  GET_ORDER_MESSAGES_HISTORY: "GET_ORDER_MESSAGES_HISTORY",
  SEND_ORDERS_MESSAGE: "SEND_ORDERS_MESSAGE",
  GET_EXCEL_ORDERS_REPORT: "GET_EXCEL_ORDERS_REPORT",
  GET_EXCEL_SITES_ORDERS_CONCENTRATION_REPORT: "GET_EXCEL_SITES_ORDERS_CONCENTRATION_REPORT",
  GET_PDF_SITE_REPORT: "GET_PDF_SITE_REPORT",
  UNPAIR_LOCKS_FROM_CELLS: "UNPAIR_LOCKS_FROM_CELLS",
  ADD_FW_VERSION: "ADD_FW_VERSION",
  UPDATE_FW_VERSION: "UPDATE_FW_VERSION",
  GET_FW_VERSION: "GET_FW_VERSION",
  REMOVE_FW_VERSION: "REMOVE_FW_VERSION",
  CHANGE_LOCK_STATUS: "CHANGE_LOCK_STATUS",
};
